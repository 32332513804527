export default {
  title: 'Learner Groups',
  desc:
    'Add learners into a group and manage. For example, you can club learners from the same company into a learner group.',
  empty: 'No learner groups found!',
  empty_cta: 'Create Learner Group!',
  table: {
    group_details: 'Learner Group Name',
    type: 'Group Type',
    priority: 'Priority',
    learners: `Number of learners`,
    conditions: 'Display Conditions',
    learning_set: 'Learning Content'
  },
  button: {
    add: 'Add Learner Group',
    remove: 'Remove',
    add_learner: 'Add Learner',
    add_recommended_videos: 'Add Recommended Videos',
    add_learning_paths: 'Add Collections',
    click_to_add: 'Click to add learner',
    create: 'Create Learner Group',
    edit_learner_group: 'Edit Learner Group',
    delete_learner_group: 'Delete Learner Group',
    add_conditions: 'Add / Edit Conditions',
    add_more_condition: 'Add Condition',
    update: 'Update Conditions'
  },
  create: {
    title: 'Create Learner Group',
    name: 'Display Name',
    group_type: 'How do you want to create the group?',
    static: 'Static Group',
    static_desc: 'Add learners to the group manually.',
    dynamic: 'Dynamic Group',
    dynamic_desc:
      'Specify conditions and the learners matching these conditions will be part of this group.'
  },
  edit: {
    title: 'Edit Learner Group'
  },
  detail: {
    course_assignments: {
      title: 'Course Assignments',
      empty: 'No Courses has been assigned directly to this learner.',
      add: 'Add'
    },
    learners: {
      title: 'Learners',
      empty: 'No learners yet!',
      view: 'View Learners'
    },
    managers: {
      title: 'Managers',
      empty: 'This group is not associated with any managers.'
    },
    content: {
      title: 'Hub Learning Content',
      desc:
        'Personalise knowledge hub with a custom set of recommended videos and collections.',
      recommended_title: 'Recommended Videos',
      learning_path_title: 'Collections',
      recommended_videos_empty: 'No recommended videos found!',
      learning_path_empty: 'No collections attached!'
    },
    conditions: {
      title: 'Dynamic Conditions',
      empty: 'No conditions added yet!',
      desc:
        'Learners data matching the given conditions will be associated with this learning group.',
      operand: 'Operand',
      operator: 'Operator',
      value: 'Value'
    },
    actions: {
      delete: {
        title: 'Delete Learner Group',
        desc: 'The learner group will be deleted permanantly.'
      }
    }
  }
};
