export default {
  title: 'Academy',
  description:
    'Create courses for your users, and link them using academy builder.',
  description_alt:
    'Build an enterprise-grade Customer Academy designed to increase product adoption, retention, and customer advocacy.',
  tab: {
    course: {
      title: 'Course Builder',
      desc: 'Edit course settings, import your videos as video lessons, and vóila — a new course.',
      cta: 'Create a course',
    },
    builder: {
      title: 'Academy Builder',
      desc: 'Offer courses personalised for your users.',
      cta: 'Go to Academy Builder',
    },
    event: {
      title: 'Training Events',
      desc: 'Create and manage Instructor led training sessions for your learners. ',
      cta: 'Create Event',
    },
  },
  button: {
    enable: 'Enable Academy',
    disable: 'Disable Academy',
  },
  quiz: {
    untitled: 'Untitled Quiz',
    imported_successfully: 'Quiz Imported Successfully',
    title: 'Edit Questions',
    description:
      'Add multiple choice / checkbox type questions to your assessment.',
    question: {
      title: 'Question Title',
      type: 'Question Type',
      add_option: 'Add option',
      add: 'Add Question',
      answer_hint: 'Set as Answer',
      saved: 'Question saved',
      deleted: 'Question deleted',
      upload: 'Upload Image',
      generate: "Generate Questions",
      generating: "Generating..."
    },
    answer_key: 'Answer key',
    point: 'point',
    points: 'points',
    choose: 'Choose correct answers:',
    quiz_settings_updated: 'Quiz settings updated',
    generate: {
      title: 'Generate Questions',
      desc: 'Trainn will generate the questions from selected lessons',
      questions_count: "Max Questions Count",
      choices_count: "Max Choices Count",
      no_lessons: "No lessons available for selection",
    }
  },
  analytics: {
    allow_crawling: 'Allow google to crawl your academy',
    allow_crawling_label: 'For Search Engine Optimisation',
    allow_ga_label: 'For Google Analytics',
    allow_ga: 'Enable Google Analytics',
    enter_tracking_code: 'Enter code',
    tracking_code_label: 'Google Analytics tracking code',
    tracking_code_desc: 'Copy and paste your Google Analytics tracking code',
    cross_origin_tracking_label: 'Enable cross origin tracking',
    origin_ids_label: 'Origin Ids',
    origin_ids_placeholder: 'Enter domain names',
    origin_ids_desc: 'Use comma to separate domain names',
  },
  lesson: {
    untitled: 'Untitled Lesson',
    delete: 'Delete Lesson',
  },
  courses: {
    course_name: 'Course Name',
    all_corses: 'All Courses',
    create: 'Create Course',
    link_course: 'Link Course',
    course_builder: 'Course Builder',
    untitled: 'Untitled Course',
    desc_placeholder:
      'Write a brief course overview — this will be displayed on the course card making it easier for your learners to grasp course benefits.',
    sort: {
      based_on: 'Sort your courses based on recently',
      created: 'created',
      updated: 'updated',
      published: 'published',
    },
    publish: 'Publish Course',
    preview: 'Preview Course',
    reviews: 'Reviews',
    published_successfully: 'Course published succesfully',
    sub_heading:
      '<b>Create a Course</b> — will be added to your “All Courses” library',
    analytics: 'Analytics',
    upload: {
      title: 'Course Cover',
      type: 'Upload Image',
      desc: 'upto 1920x1280',
      from_library: 'Choose Video',
      from_library_desc: 'from Library',
      pdf: 'Upload PDF',
      loading: 'Uploading...',
      video: 'Video Updated Successfully',
    },
    general: {
      details: 'Course Description',
      description: 'Edit this course’s description:',
      comment_title: 'Learners can comment on your video lessons',
      comment_desc:
        'Comments are public— anyone who can access this course can see these comments.',
      internal_name: 'Internal Name',
      internal_name_desc:
        'This name will not be visible to learners and will be helpful to identify multi-lingual courses.',
      chapters_title: 'Learners should take the lessons in a sequential manner',
      chapters_desc:
        'This setting will force your learners to consume the course content sequentially.',
      seeking_title: 'Learners should watch the full video to complete the video lesson',
      seeking_desc: 'This setting will prevent the learners from jumping forward in the video.',
      autoplay_toggle_title: 'Learners can choose to autoplay video lessons while taking the course.',
      autoplay_toggle_desc:
        'This setting will show an autoplay option for the learners while taking a video lesson.',
      playback_speed_control_title: 'Learners can control the playback speed of the video',
      playback_speed_control_desc:
        'This setting will allow learners to control the playback speed of the video.',
      assesment_title:
        'Learners can get certified through an assessment at the end of this course',
      assesment_desc:
        'You will be required to set up a detailed assessment round under the “Exam & Certification” menu',
      certificate_title: 'Learners can get certified through this course',
      certificate_desc:
        'Learners can get certified after a course completion or via an assessment.',
      include_pricing_title: 'Make this a paid course',
      include_pricing_desc: 'Learners have to pay to take the course',
      course_feedback_title: 'Learners can give feedback on this course',
      course_feedback_desc:
        'Feedback is used for course popularity and ranking',
      stripe_price_id_title: 'Stripe Product',
      stripe_price_id_desc: 'Select Stripe Product for the course',
      strikethrough_price_title: 'Original Price',
      strikethrough_price_desc: 'Original price of the course',
      discount_price_title: 'Discounted Price',
      discount_price_desc:
        'Final price that is to be payable after discount to access the content',
      currency_title: 'Currency',
      currency_desc:
        'Currency in which the course has to be charged to access the content',
      price: "Price",
      author: {
        label: 'Add Course Authors',
        desc: 'Enable this setting to add authors for this course',
        add: 'Add Author Information',
        modal_title: 'Add Author Details',
        modal_desc:
          'You can choose from the list or add new author information',
        no_author_found: 'No authors found',
      },
    },
    chapter: {
      rename_chapter: 'Rename Chapter',
      create_chapter: 'Create Chapter',
      chapter_name: 'Chapter Name',
      untitled: 'Untitled Chapter',
      add: 'Add Chapter',
      empty:
        'Start by adding a chapter — which helps you to group video lessons on a topic together.',
      reorder: 'Reorder you chapters',
      reorder_desc:
        'Reorder your chapters the way you want them to appear in the end user interface.',
      delete: {
        title: 'Are you sure you want to delete this Chapter?',
        description: 'On deleting, this Chapter will be deleted forever.',
        delete_btn: 'Yes, Delete it!',
        cancel: "No, don't delete it",
      },
      title_updated: 'Chapter renamed',
      deleted: 'Chapter deleted',
      added: 'Chapter added',
    },
    lesson: {
      untitled: 'Untitled Lesson',
      add: 'Add Lesson',
      edit: 'Edit Video Lesson',
      from_library: '',
      description_label: 'Lesson Description',
      description_caption: 'Edit this lesson’s description.',
      supporting_document: 'Supporting Documents',
      maximum_upload_size: 'Maximum Upload Size: %{size}MB',
      embed_url: 'Embed Url',
      embed_caption: 'Enter the url to view the page.',
      article: {
        edit: 'Edit Article Lesson',
        description_label: 'Article Content',
        description_caption: 'Edit this articles’s content.',
        import_description_label: 'Trainn Library Article',
        import_description_caption: 'Article is present in Trainn Library. Click here to edit.',
      },
      delete: {
        title: 'Are you sure you want to delete this lesson?',
        description:
          'On deleting, this lesson will be deleted forever. All the links associated with this lesson will not be available anymore.',
        delete_btn: 'Yes, Delete it!',
        cancel: "No, don't delete it",
      },
      deleted: 'Lesson deleted',
      questions: 'Questions',
      quiz_settings: 'Quiz settings',
      shuffle_question_order_label: 'Shuffle question display order',
      shuffle_question_order_desc:
        'The questions will be dynamically re-ordered for your each user attempting the quiz.',
      shuffle_choice_order_label: 'Shuffle options',
      shuffle_choice_order_desc:
        'The answer choices will be dynamically shuffled for each users attempting the quiz.',
      guide_assessment_mode_label: 'Enable assessment mode',
      guide_assessment_mode_desc:
        'The guide will be shown to learners without any help texts and spotlights.',
      success: {
        lessons_reordered: 'Lessons reordered',
        lesson_updated: 'Lesson updated',
        resource_uploaded: 'Resource uploaded successfully',
        resource_deleted: 'Resource deleted successfully',
        file_uploaded: 'File uploaded',
        video_updated: 'Lesson video updated',
        chapters_reordered: 'Chapters reordered',
      },
    },
    empty: 'Your Course Library is empty. Start by creating a course.',
    delete: {
      title: 'Are you sure you want to delete this course?',
      description:
        'On deleting, this course will be deleted forever. All the links associated with this course will not be available anymore.',
      delete_btn: 'Yes, Delete it!',
      cancel: "No, don't delete it",
    },
    assessment: {
      upload_question: 'Upload Questions',
      add_update_questions: 'Questions',
      question_description:
        'Add multiple choice / checkbox type questions to your assessment',
      quiz_builder: 'Quiz Builder',
      go_to_quiz_add: 'Add Questions',
      go_to_quiz_edit: 'Edit Questions',
      import_questions: 'Import Questions',
      upload_question_desc: 'Watch video to learn more',
      without_completeing_course_label:
        'Take assessment without course completion ',
      without_completeing_course_desc:
        'Your learners can take the assessment without completing the course.',
      minimum_duration_label: 'Estimated time to finish the assessment',
      minimum_duration_desc:
        'This is to inform your learners about the average time it takes to complete the assessment.',
      maximum_duration_label: 'Assessment duration',
      maximum_duration_desc:
        'Keep the portal open for a certain duration before auto-submitting.',
      can_retake_label: 'Can retake the assessment',
      can_retake_desc: 'Learner can retake the assessment even after certification',
      pass_percentage_label: 'Mininum score',
      pass_percentage_desc: 'Minimum score required to complete the quiz',
      exam_pass_percentage_desc: 'Minimum score required to complete the exam',
      attempts_label: 'Number of attempts allowed',
      attempts_desc:
        'Set the number of times your learners can take the assessment',
      attempts_gap_label: 'Reattempt window',
      attempts_gap_desc:
        'Set the duration your learners have to wait between reattempts of the assessment, on failure.',
      attempt_summary_label: 'Show quiz attempt summary',
      attempt_summary_desc:
        'After quiz completion, show whether the question was answered correctly or not',
      correct_answers_label: 'Show correct answers in the summary',
      correct_answers_desc: 'After quiz completion, show the correct answers for each question within the summary view.',
      no_attempts: 'One attempt ',
      three_attempts: '3 attempts',
      unlimited_attempts: 'Unlimited attempts',
      title: 'Untitled Quiz',
      custom: 'Custom',
      hours: 'Hours',
      hour: 'Hour',
      mins: 'Mins',
      error: {
        question_title: 'Please fill the question title',
        question_points: 'Please fill the question points',
        option_text: 'Please fill the option text',
        option_correct: 'Please select the correct option',
        duplicate_choices: 'Please enter unique choices',
      },
      exam_settings: 'Exam Settings',
      no_time_limit: 'No limit',
      thirty_minutes: '30 minutes',
      sixty_minutes: '60 minutes',
    },
    quiz: {
      maximum_duration_label: 'Quiz duration',
      maximum_duration_desc:
        'Keep the quiz open for your learners for a certain duration before it auto-submits.',
      attempts_label: 'Number of attempts allowed',
      attempts_desc:
        'Set the number of times your learners can attempt the quiz',
    },
    guide: {
      title: 'Untitled Guide',
      settings_updated: 'Guide settings updated'
    },
    task: {
      offline: 'Offline',
      file_upload: 'File upload',
      free_text: 'Free text',
      task_settings_updated: 'Task Settings Updated',
      tabs: {
        description: 'Description',
        settings: 'Settings'
      },
      task_type_title: 'Task type',
      task_type_desc: 'Choose the type of task to be assigned to your learner',
      cta_title: 'Button label',
      cta_desc: 'Edit the text for the submission button',
    },
    certificate: {
      validity_label: 'Certification Validity',
      validity_desc:
        'From the date of issuance, how long is the certificate valid for',
      content_label: 'Certification Text',
      content_desc: 'This template will be reflected across all certificates',
      auth_label: 'Authorization Information',
      auth_desc:
        'Enter the details of the signing authority of the certification',
      logo_label: 'Logo on the certificate',
      logo_desc: 'Upload a transparent .png of the company logo',
      content_text1: 'This is to certify that',
      content_text2: 'has successfully completed the course',
      learner_name: 'learner name',
      course_name: 'course name',
      logo_file_desc: '<b>Signature:</b> Upto 2800x1800 | .png',
      authority_name: 'Name of the authority',
      signature_preview: 'Signature Preview',
      course_certificate_name: 'Course Certificate Name',
      course_certificate_name_desc:
        'Use a different name from the course title for the certification. ',
      settings: 'Certification Settings',
      settings_updated: 'Certification settings has been updated',
      certification_method: 'Certification Method',
      certification_method_desc:
        'Choose how your learners need to get certified.',
      completion_percentage: 'Course Completion Percentage',
      completion_percentage_desc:
        'The percentage of the course that needs to be completed to get certified.',
    },
    table: {
      name: 'Name',
      authors: 'Authors',
      status: 'Status',
      recent_activity: 'Recent activity',
      active: 'Active',
      drafting: 'Drafting',
    },
    edit_course: 'Edit',
    preview_course: 'Preview',
    clone_course: 'Clone',
    delete_course: 'Delete',
    last_updated: 'Last updated:',
    settings_updated: 'Course settings updated',
    authors_updated: 'Authors updated',
    cover_updated: 'Course cover updated',
    nav: {
      course_overview: 'Course Overview',
      course_content: 'Course Content',
      exam_certification: 'Assessment & Certification',
      exam: 'Exam',
      exam_settings: 'Assessment Settings',
      exam_questionnaire: 'Assessment Questionnaire',
      quiz_settings: 'Settings',
      quiz_questionnaire: 'Questionnaire',
      certification: 'Certification',
      certification_settings: 'Settings',
      no_lessons_found: 'Click + icon to add lesson',
      add_chapter: 'Add Chapter',
      add_lesson: 'Add Lesson',
      popover: {
        edit_name: 'Rename Chapter',
        reorder: 'Reorder Chapter',
        delete: 'Delete Chapter',
        choose_lesson_type: 'Choose Lesson Type',
        video_lesson: 'Video Lesson',
        add_article: 'Add Article',
        article_lesson: 'Article Lesson',
        add_pdf: 'Add Pdf',
        add_knowledge_check: 'Add Quiz',
        add_task_lesson: 'Add Task',
        add_guide: 'Add Guide',
        add_embed_url: 'Add Embed'
      },
    },
    unsaved_changes_modal: {
      title: 'You have unsaved changes',
      description: 'Save or Discard the changes before navigating',
      discard: 'Discard and Continue',
      save: 'Save and Continue',
    },
    title_updated: 'Course renamed',
    deleted: 'Course deleted',
  },
  events: {
    untitled: 'Untitled Event',
    last_updated: 'Last updated:',
    preview: 'Preview Event',
    copy_link: 'Copy Registration Link',
    event_name: 'Event Name',
    title_updated: 'Event renamed',
    nav: {
      event_overview: 'Event Overview',
      sessions: 'Upcoming Sessions',
      past_session: 'Past Sessions',
      add_session: 'Add Session'
    },
    tab: {
      session_settings: 'Settings',
      session_attendees: 'Attendees',
    },
    session: {
      untitled: 'New session',
      add: 'Add session',
      session_name: 'Session name',
      create_session: 'Create session',
      cancelled: 'Session cancelled',
      deleted: 'Session deleted',
      added: 'Session added',
      session_settings_updated: 'Session settings updated',
      no_attendees: 'No Attendees found',
      session_progress: 'Session in progress',
      session_ended: 'Session has ended',
      session_cancelled: 'Session cancelled',
      invited: 'Learners has been invited',
      delete: {
        title: 'Are you sure you want to delete this session?',
        delete_btn: 'Yes, Delete it!',
        cancel: "No, don't delete it",
      },
      table: {
        name: 'Name',
        email: 'Email',
        status: 'Status',
        joined: 'Joined',
        registered: 'Registered',
        invited: 'Invited',
      },
      settings: {
        unlimited: 'Unlimited',
        date: 'Session date',
        date_desc: 'Choose a date for the session',
        start_time: 'Start time',
        start_time_desc: 'Choose a start time for the session',
        session_duration: 'Session duration',
        session_duration_desc: 'Enter a duration for the session in minutes',
        session_url: 'Session URL',
        session_url_desc: 'Enter the session URL',
        session_instructor: 'Instructor',
        session_instructor_desc: 'Select an instrustor from the list available',
        session_seats: 'Available seats',
        session_seats_desc: 'Select the maximum number of seats available for the session or select the checbox for unlimited seats',
      }
    },
    desc_placeholder:
      'Write a brief event overview — this will be used in the event page and email communications to your learners.',
    long_desc_placeholder:
      'Write a detailed description about this event to help your learners undersand what they can expect and gain out of this event.',
    general: {
      details: 'Short Description',
      description: 'Edit this event’s summary:',
      access_type: 'Access type',
      access_type_desc: 'On selecting access type will be set to academy',
      time_zone: 'Time Zone',
      time_zone_desc: 'Choose a time zone in which the sessions will be scheduled. This will help your learners to register with ease.',
      empty_fields_message: 'Fields must not be left empty',
      default_time_message: 'Choose a valid time',
      delete: 'Delete Event',
      invalid_url: 'Enter a valid session URL',
      events_deleted: 'Event deleted',
      delete_title: 'Are you sure you want to delete this event?',
      delete_description: 'Deleting this event will cancel all your upcoming sessions. Learners who registered for those sessions will be notified.',
      empty_summary: 'Please enter a short description for this event',
      display_in_academy: 'Display this event in Academy',
      display_in_academy_desc: 'On enabling this event will be listed in the academy list of events.'
    },
    table: {
      event_name: 'Event name',
      next_session: 'Next session',
      learner_name: 'Name',
      registered: 'Registered',
      invited: 'Invited',
      cancelled: 'Cancelled',
    },
    empty: 'No Training events have been created. Start by creating one.'
  },
  builder: {
    publish: 'Publish Academy',
    published_successfully: 'Academy published successfully',
    upload: {
      title: 'Page Cover',
    },
    delete: {
      title: 'Are you sure you want to delete %{page} page?',
      description: 'On deleting, %{page} page will be deleted forever.',
      delete_btn: 'Yes, Delete it!',
      cancel: "No, don't delete it",
    },
    language: {
      delete: {
        title: 'Are you sure you want to delete %{language} Academy Page?',
        description:
          'On deleting, %{language} Academy Page will be deleted forever.',
        button: 'Yes, Delete it!',
        cancel: "No, don't delete it",
      },
    },
    pages: {
      login: 'Login Page',
      home: 'Home Page',
    },
    login: {
      title: 'Login Settings',
      public: {
        caption: 'Your Academy is Public',
        title: 'Everybody can access your academy',
      },
      private: {
        caption: 'Your Academy is Private',
        title: 'Authenticate your users before allowing',
        custom_sso:
          'You have configured a custom SSO implementation. Contact our support team to modify',
      },
      auth_type: {
        sso: 'Use SSO Authentication',
        sso_documentation_link:
          "Read this <a target='_blank' href='https://help.trainn.co/share/dabTAiuVyypmrtWwGmDL9w'>documentation</a> on how to integrate SSO with video academy.",
        train_login: 'Use Email Authentication',
        train_auth_customer_signup: 'Allow anyone to signup',
        login_url: 'Paste redirection URL',
        login_url_label: 'SSO Authentication',
        saml: 'Single Sign-On SAML',
        saml_documentation_link:
          "Check this <a target='_blank' href='https://help.trainn.co/share/BxVWDODPORX6v0vUn2F3JQ'>documentation</a> on how to integrate SSO SAML with video academy.",
        saml_login_url: 'Login URL',
        saml_login_url_desc: 'Users will be redirected to this URL to login.',
        saml_certificate: 'SAML Certificate',
        saml_certificate_desc:
          'SAML X.509 certificate to authenticate with your Identity Provider',
        logout_url: 'Remote Logout URL (optional)',
        logout_url_desc:
          'Users will be redirected to this URL when they logout from Hub',
      },
    },
    design: {
      login_title: 'Login Page Design',
      home_title: 'Home Page Design',
      header: 'Edit page heading',
      sub_header:
        'Edit page sub-heading: Give a hearty welcome to your users or a brief overview of what they can expect from your academy',
      layout_title: 'Choose Layout',
      layout: {
        left: 'Form left on the page',
        center: 'Form centered on the page',
        right: 'Form right on the page',
      },
      home_layout: {
        left: 'Banner Image aligned to the left of the page',
        center: 'Banner Image centered on the page',
        right: 'Banner Image aligned to the right of the page',
      },
      align: {
        left: 'Linear with left aligned headers',
        center: 'Linear with left aligned headers',
        right: 'Linear with left aligned headers',
      },
      brand: 'Add Brand Essentials',
    },
    settings: {
      title: 'Advanced Settings',
      header: {
        caption: 'Header',
        title: 'You can add up to three links in your academy header',
      },
      footer: {
        caption: 'Foot notes',
        title: 'Enable a footer for the page',
      },
      add_login_foot_note:
        'Add a footnote to the login page — add links if you need',
      add_foot_note:
        'Add a footnote to direct your users to your desired page in case they need further support',
      link_text: 'Link Text',
      label: 'Label',
      url: 'URL',
      edit_home_page:
        'Customise the home page of your academy by linking desired courses',
      edit_home_cta: 'Edit Home Page',
      override_home_page: 'Show different courses for custom user segments',
      override_home_cta: 'Create New Home Override',
      override_title:
        'Override: Create a new home override for custom user segments',
      override_title_placeholder: 'Name it for your reference',
      override_title_desc:
        'In the following step, you can set up who has access to this page',
      override_title_cta: 'Create Page',
      add_link: 'Add link',
      edit_link: 'Edit link',
      remove_link: 'Remove link',
    },
    content: {
      link_courses: 'Link Courses',
    },
    categories: {
      untitled: 'Untitled Category',
      add: 'Add Category',
      empty:
        'Start by creating a category — which helps you to group courses together.',
      reorder: 'Reorder your categories',
      delete: {
        title: 'Are you sure you want to delete this Category?',
        description: 'On deleting, this Category will be deleted forever.',
        delete_btn: 'Yes, Delete it!',
        cancel: "No, don't delete it",
      },
    },
  },
  conditions: {
    title: 'Who can see this page?',
  },
  config: {
    title: 'Academy Settings',
    desc: 'Set up analytics, custom domain, and more for your academy',
    cta: 'Save Configurations',
    brand_essentials: 'Brand Essentials',
    brand_identity: 'Brand Identity',
    brand_color: 'Brand Colour',
    brand_color_desc:
      'Your brand color will be used for visual highlights in your academy',
    logo_title: '<b>Logo: </b>upto 240x120',
    favicon_title: '<b>Favicon: </b>30x30',
    hex: '#000FF0',
    analytics: 'Analytics',
    updated: 'Settings updated successfully',
    image_updated: 'Image updated successfully',
    copy_url: 'Copy Academy URL',
    go_to: 'Go to Academy',
    moderators: 'Moderators',
    moderators_desc: 'Add moderators to your academy',
    languages: {
      title: 'Languages',
      desc: 'Add languages to your academy',
      add: 'Add Language',
      default: 'Default Language',
      popover_title: 'Add Academy Languages',
    },
  },
  email: {
    title: 'Email Notification',
    desc: 'Customize and configure emails',

    welcome_email: {
      title: 'Welcome Mail',
      desc: 'Personalized welcome mail would be sent when a learner sign’s up in the Academy',
    },
    invite_email: {
      title: 'Invite Mail',
      desc: 'Personalized Invite mail would be generated when the admin invites a learner.',
    },
    course_completion_email: {
      title: 'Course Completion Reminder',
      desc: 'A weekly reminder mail would be sent to the learners to complete unfinished courses based on their last engagement time.',
    },
    course_assigning_email: {
      title: 'Course Assigning Mail',
      desc: 'A mail would be sent everytime when a admin assigns a new course to the learners.',
    },

    edit_template: 'Edit Template',
    reminder_in_weeks:
      "Number of weeks to follow up after the learners' last engagement",
  },
  reorder: 'Reorder you chapters',
  learner_groups: {
    static: {
      label: 'Choose users ',
      desc: 'You can choose one or multiple customers',
      search: 'Search customers…',
    },
    dynamic: {
      label: 'Filter dynamically through authentication',
      add_condition: '+ Add Condition',
    },
  },
  course_feedbacks: {
    title: 'Course Feedbacks',
    desc: 'Feedback for your course',
    empty: 'No feedbacks yet',
    rating: 'Rating',
    comment: 'Comment',
    name: 'Name',
    email: 'Email',
    company: 'Company',
    date: 'Date',
  },
  course_analytics: {
    analytics: 'Analytics',
    learners_enrolled: 'Learners Enrolled',
    learners_completed: 'Learners Completed',
    assessment_taken: 'Assessment Taken',
    certified_learners: 'Certified Learners',
    name: 'Name',
    email: 'Email',
    company: 'Company',
    enrolled_on: 'Enrolled On',
    completed: 'Completed',
    certified: 'Certified',
    assessment_attempts: 'Assessment Attempts',
    last_assessment_score: 'Last Assessment Score',
    yes: 'Yes',
    no: 'No',
    no_results_found: 'No results found!',
    export: {
      button: 'Export',
      title: 'Export Course Analytics',
      desc: `A CSV file containing the course analytics will be sent to your email.`,
    },
    course_reviews: 'Course Reviews',
  },
  analytics_dashboard: {
    title: 'Academy Home',
    description:
      'Delve into your academy data and analytics (Updated every 24 hours)',
    empty_dashboard: 'No analytics found!',
    live_courses: 'Live Courses',
    enrolled_courses: 'Total Course Enrollments',
    completed_courses: 'Total Course Completions',
    total_certifications: 'Total Certificates Issued',
    total_learners: 'Total Number of Learners',
    total_enrolled_companies: 'Total Enrolled Companies',
    total_course_enrolment: 'Total Course Enrolments',
    total_course_completions: 'Total Course Completions',
    total_certificates_issued: 'Total Certificates Issued',
    number_of_signups: 'Number of Signups',
    signups: 'Signups',
    top_companies: 'Top Companies',
    course_enrollment: 'Course Enrollment',
    enrollments: 'Enrollments',
    top_courses: 'Top Courses',
    top_learners: 'Top Learners',
    active_learners: 'Unique Active Learners',
    learners: 'Learners',
    top_learnres: 'Top Learners',
    total_engagement_time: 'Total Engagement Time',
    engagement: 'Engagement',
    average_engagement_time: 'Average Engagement Time',
    retention_cohort: {
      title: 'Retention Cohort Analysis',
      week: 'Week',
      signed_up: 'Signed up users',
      desc: 'No. of users active in the consecutive weeks',
    },
    duration: 'Duration',
    filters: {
      all_companies: 'All Companies',
      no_of_enrollments: 'By No of Enrollments',
      unique_active_learners: 'By Unique Active Learners',
      course_completions: 'By Course Completions',
      certifications_issued: 'By Certifications Issued',
      engagement_time: 'By Engagement Time',
      number_of_certificates: 'By Number of Certificates',
      courses_completed: 'By Courses Completed',
      enrollments: 'enrollments',
      learners: 'learners',
      completions: 'completions',
      certifications: 'certifications',
      courses: 'courses',
      certificates: 'certificates',
    },
    start_sharing_courses: 'Start sharing your courses!',
  },
  course_quiz_attempts: {
    quiz_attempts: 'Quiz Attempts',
    name: 'Quiz Name',
    learner_name: 'Attempted By',
    attempt_date: 'Attempted At',
    attempt_count: 'No Of Attempt',
    score: 'Score',
    status: 'Status',
    latest_attempt: 'Show latest attempts'
  }
};