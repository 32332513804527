import Vue from 'vue';
import importWrapper from '@/lib/utils/chunk-import-wrapper';
import Router from 'vue-router';
import { AuthService } from '../services/auth-service';

declare let PARTNER_MODE: boolean;

enum Tabs {
  quickStartGuide = 'quick_start_guide',
  dashboard = 'dashboard',
  videos = 'videos',
  library = 'library',
  articles = 'articles',
  collections = 'collections',
  academy = 'academy',
  hub = 'hub',
  learners = 'learners',
  learnerGroup = 'learner_group',
  integrations = 'integrations',
  settings = 'settings',
  trash = 'trash',
  emails = 'emails',
}

const CommonLoginComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "login" */ '@/app/pages/CommonLoginComponent.vue'
  );
});

const HomeComponent = importWrapper(() => {
  return import(/* webpackChunkName: "home" */ '@/app/pages/HomeComponent.vue');
});

const LogoutComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "home" */ '@/app/pages/LogoutComponent.vue'
  );
});

const VideoLibrary = importWrapper(() => {
  return import(
    /* webpackChunkName: "videos_home" */ '@/app/pages/VideoLibrary.vue'
  );
});

const TrashComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "videos_home" */ '@/app/pages/TrashComponent.vue'
  );
});

const CollectionListComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "collections_home" */ '@/app/pages/CollectionListComponent.vue'
  );
});

const JoinWorkspaceComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/JoinWorkspaceComponent.vue'
  );
});

const CommonLoaderComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/CommonLoaderComponent.vue'
  );
});

const YoutubeCallbackComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/YoutubeCallbackComponent.vue'
  );
});

const EditVideoComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "edit" */ '@/app/pages/EditVideoComponent.vue'
  );
});

const PreviewComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "preview" */ '@/app/components/edit/PreviewComponent.vue'
  );
});

const WorkspaceSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/Home.vue'
  );
});

const VideoRecordingsComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "video-recordings" */ '@/app/components/edit/VideoRecordingsComponent.vue'
  );
});

const VideoComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "video-component" */ '@/app/pages/VideoComponent.vue'
  );
});

const ShowVideoComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "home" */ '@/app/pages/ShowVideoComponent.vue'
  );
});

const VideoAnalytics = importWrapper(() => {
  return import(
    /* webpackChunkName: "video-analytics" */ '@/app/pages/VideoAnalytics.vue'
  );
});

const GuideAnalytics = importWrapper(() => {
  return import(
    /* webpackChunkName: "video-analytics" */ '@/app/pages/GuideAnalytics.vue'
  );
});

const Collection = importWrapper(() => {
  return import(
    /* webpackChunkName: "collection" */ '@/app/pages/Collection.vue'
  );
});

const ShowCollectionComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "show-collection" */ '@/app/pages/ShowCollectionComponent.vue'
  );
});

const CollectionAnalytics = importWrapper(() => {
  return import(
    /* webpackChunkName: "collection-analytics" */ '@/app/pages/CollectionAnalytics.vue'
  );
});

const EmbedVideoComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "embed-video" */ '@/app/pages/EmbedVideoComponent.vue'
  );
});

const SignUpComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "signup-component" */ '@/app/pages/SignUpComponent.vue'
  );
});

const HubSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "hub-settings" */ '@/app/pages/HubSettings.vue'
  );
});

const Academy = importWrapper(() => {
  return import(/* webpackChunkName: "academy" */ '@/app/pages/Academy.vue');
});

const AcademyAnalytics = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-analytics-new" */ '@/app/pages/AcademyAnalytics.vue'
  );
});

const AcademyCourses = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-courses" */ '@/app/pages/Courses.vue'
  );
});

const Courses = importWrapper(() => {
  return import(
    /* webpackChunkName: "all-courses" */ '@/app/pages/Courses.vue'
  );
});

const AcademyBuilder = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/pages/AcademyBuilder.vue'
  );
});

const AcademyConfigurations = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/pages/AcademyConfigurations.vue'
  );
});

const AcademyEvents = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/pages/AcademyEvents.vue'
  );
});

const Emails = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/pages/Emails.vue'
  );
});

const EmailSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/components/email-settings/EmailSettings.vue'
  );
});

const EmailNotifications = importWrapper(() => {
  return import(
    /* webpackChunkName: "academy-builder" */ '@/app/components/email-settings/EmailNotifications.vue'
  );
});

const EditCourse = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditCourse.vue'
  );
});
const EditCourseSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditCourseSettings.vue'
  );
});
const CourseContent = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/CourseContent.vue'
  );
});
const EditLesson = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditLesson.vue'
  );
});
const EditAssessmentSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditAssessmentSettings.vue'
  );
});
const EditAssessment = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditAssessment.vue'
  );
});
const EditCertificate = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-creation" */ '@/app/pages/EditCertificate.vue'
  );
});

const CourseAnalytics = importWrapper(() => {
  return import(
    /* webpackChunkName: "course-analytics" */ '@/app/pages/CourseAnalytics.vue'
  );
});
// const CourseAnalyticsNew = importWrapper(() => {
//   return import(
//     /* webpackChunkName: "course-analytics-new" */ '@/app/pages/CourseAnalyticsNew.vue'
//   );
// });

// const Customers = importWrapper(() => {
//   return import(
//     /* webpackChunkName: "customers" */ '@/app/pages/Customers.vue'
//   );
// });

const Learners = importWrapper(() => {
  return import(/* webpackChunkName: "learners" */ '@/app/pages/Learners.vue');
});

const LearnerGroup = importWrapper(() => {
  return import(
    /* webpackChunkName: "learner-group" */ '@/app/pages/LearnerGroup.vue'
  );
});

const LearnerDetails = importWrapper(() => {
  return import(
    /* webpackChunkName: "learner-details" */ '@/app/pages/LearnerDetails.vue'
  );
});

const LearnerGroupDetails = importWrapper(() => {
  return import(
    /* webpackChunkName: "learner-group-details" */ '@/app/pages/LearnerGroupDetails.vue'
  );
});

const LearnerGroupMembers = importWrapper(() => {
  return import(
    /* webpackChunkName: "learner-group-details" */ '@/app/pages/LearnerGroupMembers.vue'
  );
});

const Dashboard = importWrapper(() => {
  return import(
    /* webpackChunkName: "dashboard" */ '@/app/pages/Dashboard.vue'
  );
});

const QuickStartGuide = importWrapper(() => {
  return import(
    /* webpackChunkName: "quick-start-guide" */ '@/app/pages/QuickStartGuide.vue'
  );
});

const IntercomCallbackComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/IntercomCallbackComponent.vue'
  );
});

const IntercomCallbackDirectComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/IntercomCallbackDirectComponent.vue'
  );
});

const IntercomConnectComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "session" */ '@/app/pages/IntercomConnectComponent.vue'
  );
});

const Integrations = importWrapper(() => {
  return import(
    /* webpackChunkName: "integrations" */ '@/app/components/settings/Integrations.vue'
  );
});

const YoutubeIntegration = importWrapper(() => {
  return import(
    /* webpackChunkName: "youtube" */ '@/app/components/settings/integrations/YoutubeIntegration.vue'
  );
});

const IntercomIntegration = importWrapper(() => {
  return import(
    /* webpackChunkName: "intercom" */ '@/app/components/settings/integrations/IntercomIntegration.vue'
  );
});

const SalesforceIntegration = importWrapper(() => {
  return import(
      /* webpackChunkName: "session" */ '@/app/components/settings/integrations/SalesforceIntegration.vue'
  );
});

const SalesforceCallbackComponent = importWrapper(() => {
  return import(
      /* webpackChunkName: "session" */ '@/app/pages/SalesforceCallbackComponent.vue'
  );
});

const StripeConnect = importWrapper(() => {
  return import(
      /* webpackChunkName: "stripe" */ '@/app/components/settings/integrations/StripeConnect.vue'
      );
});

const StripeCallback = importWrapper(() => {
  return import(
      /* webpackChunkName: "stripe" */ '@/app/pages/StripeCallback.vue'
      );
});

const AccountSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/AccountSettings.vue'
  );
});

const WorkspaceGeneralSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/WorkspaceSettings.vue'
  );
});

const NotificationSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/NotificationSettings.vue'
  );
});

const MemberSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/MemberSettings.vue'
  );
});

const PricingPage = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/PricingSettings.vue'
  );
});

const ThemeSettings = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/ThemeSettings.vue'
  );
});

const GuideThemeSettings = importWrapper(() => {
  return import(
     /* webpackChunkName: "settings" */ '@/app/components/settings/GuideThemeSettings.vue'
  )
})

const BillingPage = importWrapper(() => {
  return import(
    /* webpackChunkName: "settings" */ '@/app/components/settings/Billing.vue'
  );
});

const SecuritySettings = importWrapper(() => {
  return import(
      /* webpackChunkName: "settings" */ '@/app/components/settings/SecuritySettings.vue'
  )
})

const RecordPageComponent = importWrapper(() => {
  return import(/* webpackChunkName: "record" */ '@/app/pages/RecordPage.vue');
});

const EditV2VideoComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "edit-v2" */ '@/app/pages/EditV2VideoComponent.vue'
  );
});

const GuideComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "guide" */ '@/app/pages/GuideComponent.vue'
  );
})

const EditGuideComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "edit-guide" */ '@/app/pages/EditGuideComponent.vue'
  );
})

const GuideRecordPage = importWrapper(() => {
  return import(
    /* webpackChunkName: "edit-guide" */ '@/app/pages/GuideRecordPage.vue'
  );
})

const ViewGuideComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "view-guide" */ '@/app/pages/ViewGuideComponent.vue'
  )
})

const DocumentComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "guide" */ '@/app/pages/DocumentComponent.vue'
  );
})


const ViewDocumentComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "view-document" */ '@/app/pages/ViewDocumentComponent.vue'
  )
})

const EditV2VideoSettingsComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "edit-v2" */ '@/app/pages/EditV2VideoSettingsComponent.vue'
  );
});

const ArticleEditComponent = importWrapper(() => {
  return import(
    /* webpackChunkName: "articles" */ '@/app/pages/ArticleEditComponent.vue'
  );
});

const ViewArticleComponent = importWrapper(() => {
  return import(
      /* webpackChunkName: "view-article" */ '@/app/pages/ViewArticleComponent.vue'
  );
});

const SsoLogin = importWrapper(() => {
  return import(
      /* webpackChunkName: "view-article" */ '@/app/components/login/SsoLogin.vue'
  );
});

// const Guidecomponent = importWrapper(() => {
//   return import(
//     /* webpackChunkName: "view-article" */ '@/app/pages/Guide.vue'
//   );
// });

const TrainingEvent = importWrapper(() => {
  return import(
    /* webpackChunkName: "training-events" */ '@/app/pages/TrainingEvent.vue'
    );
});
const TrainingEventOverview = importWrapper(() => {
  return import(
    /* webpackChunkName: "training-events" */ '@/app/pages/TrainingEventOverview.vue'
    );
});
const TrainingEventContent = importWrapper(() => {
  return import(
    /* webpackChunkName: "training-events" */ '@/app/pages/TrainingEventContent.vue'
    );
});
const TrainingEventSession = importWrapper(() => {
  return import(
    /* webpackChunkName: "training-events" */ '@/app/pages/TrainingEventSession.vue'
    );
});

Vue.use(Router);
const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    document.querySelector('#page-body')?.scrollTo(0, 0);
  },
  routes: [
    {
      path: '/:workspace/login',
      redirect: (to) => {
        return {
          name: 'common_login',
          query: {
            workspace: to.params.workspace,
          },
        };
      },
    },
    {
      path: '/login',
      component: CommonLoginComponent,
      name: 'common_login',
      meta: {
        non_logged_in: 'strict',
      },
    },
    {
      path: '/sso_auth',
      component: CommonLoginComponent,
      name: 'common_login',
      meta: {
        non_logged_in: 'strict',
      },
    },
    {
      path: '/sign_up',
      component: SignUpComponent,
      name: 'sign_up',
      meta: {
        non_logged_in: 'strict',
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'common_login' });
        } else {
          next();
        }
      },
    },
    {
      path: '/',
      component: VideoLibrary,
      name: 'videos_home',
      meta: {
        tab: Tabs.library,
      },
      redirect: '/library',
    },
    {
      path: '/getting_started',
      component: QuickStartGuide,
      name: 'get_started',
      meta: {
        tab: Tabs.quickStartGuide,
      },
    },
    {
      path: '/dashboard',
      component: Dashboard,
      name: 'dashboard',
      meta: {
        tab: Tabs.dashboard,
      },
    },
    {
      path: '/library',
      component: VideoLibrary,
      name: 'videos_home',
      meta: {
        tab: Tabs.library,
      },
    },
    {
      path: '/library/:folderId',
      component: VideoLibrary,
      name: 'folders_home',
      meta: {
        tab: Tabs.library,
      },
    },
    {
      path: '/trash',
      component: TrashComponent,
      name: 'trash',
      meta: {
        tab: Tabs.trash,
      },
    },
    {
      path: '/trash/:id',
      component: TrashComponent,
      name: 'trash',
      meta: {
        tab: Tabs.trash,
      },
    },
    {
      path: '/collections',
      component: CollectionListComponent,
      name: 'collections_home',
      meta: {
        tab: Tabs.collections,
      },
    },
    {
      path: '/academy',
      component: Academy,
      name: 'academy',
      children: [
        {
          path: '/',
          component: AcademyAnalytics,
          name: 'academy_home',
          meta: {
            tab: Tabs.academy,
            subTab: 'academy_home',
          },
        },
        {
          path: '/academy/courses',
          component: AcademyCourses,
          name: 'courses',
          meta: {
            tab: Tabs.academy,
            subTab: 'academy_builder',
          },
        },
        {
          path: '/academy/configurations',
          component: AcademyConfigurations,
          name: 'academy_configurations',
          meta: {
            tab: Tabs.academy,
            subTab: 'academy_config',
          },
        },
        {
          path: '/academy/events',
          component: AcademyEvents,
          name: 'academy_events',
          meta: {
            tab: Tabs.academy,
            subTab: 'academy_events',
          },
        },
      ],
    },
    {
      path: '/emails',
      component: Emails,
      name: 'emails',
      meta: {
        tab: Tabs.emails,
      },
      children: [
        {
          path: '/emails/settings',
          component: EmailSettings,
          name: 'email_settings',
          meta: {
            tab: Tabs.emails,
            subTab: 'email_settings',
          },
        },
        {
          path: '/emails/notifications',
          component: EmailNotifications,
          name: 'email_notifications',
          meta: {
            tab: Tabs.emails,
            subTab: 'email_notifications',
          },
        },
      ],
    },
    {
      path: '/academy/pages/:pageName',
      component: AcademyBuilder,
      name: 'academy_builder',
      meta: {
        tab: Tabs.academy,
        subTab: 'academy_builder',
      },
    },
    {
      path: '/academy/courses/:courseId',
      component: EditCourse,
      name: 'edit_course',
      meta: {
        tab: Tabs.academy,
      },
      redirect: '/academy/courses/:courseId/overview',
      children: [
        {
          path: 'overview',
          component: EditCourseSettings,
          name: 'edit_course_settings',
        },
        {
          path: 'content',
          component: CourseContent,
          name: 'course_content',
        },
        {
          path: 'lessons/:lessonId',
          component: EditLesson,
          name: 'edit_course_lesson',
        },
        {
          path: 'assessment-settings',
          component: EditAssessmentSettings,
          name: 'edit_course_assessment_settings',
        },
        {
          path: 'assessment',
          component: EditAssessment,
          name: 'edit_course_assessment',
        },
        {
          path: 'certificate',
          component: EditCertificate,
          name: 'edit_course_certificate',
        },
      ],
    },
    {
      path: '/academy/courses/:courseId/analytics',
      component: CourseAnalytics,
      name: 'course_analytics',
      meta: {
        tab: Tabs.academy,
      },
    },
    // {
    //   path: '/academy/courses/:courseId/analytics-new',
    //   component: CourseAnalyticsNew,
    //   name: 'course_analytics_new',
    //   meta: {
    //     tab: Tabs.academy
    //   }
    // },
    {
      path: '/videos/:videoId/edit',
      component: EditVideoComponent,
      name: 'edit_video',
      children: [
        {
          path: 'preview',
          component: PreviewComponent,
          name: 'preview_video',
        },
        {
          path: 'recordings',
          component: VideoRecordingsComponent,
          name: 'upload_video_recordings',
        },
      ],
    },
    {
      path: '/videos/v2/:videoId/edit',
      component: EditV2VideoComponent,
      name: 'edit_v2_video',
    },
    {
      path: '/guides/:guideId',
      component: GuideComponent,
      name: 'guide_component',
      meta: {
        tab: Tabs.library,
      },
      children: [
        {
          path: '/',
          component: ViewGuideComponent,
          name: 'show_guide',
          meta: {
            tab: Tabs.library,
          },
        },
        {
          path: 'edit',
          component: EditGuideComponent,
          name: 'edit_guide'
        },
        {
          path: 'analytics',
          component: GuideAnalytics,
          name: 'guide_analytics',
          meta: {
            tab: Tabs.library,
            fullpage: true,
          },
        }
      ]
    },
    {
      path: '/documents/:documentId',
      component: DocumentComponent,
      name: 'document_component',
      meta: {
        tab: Tabs.library,
      },
      children: [
        {
          path: '/',
          component: ViewDocumentComponent,
          name: 'show_document',
          meta: {
            tab: Tabs.library,
          },
        },
      ]
    },
    {
      path: '/videos/:videoId/settings',
      component: EditV2VideoSettingsComponent,
      name: 'edit_v2_video_settings',
    },
    {
      path: '/hub',
      component: HubSettings,
      name: 'hub',
      meta: {
        tab: Tabs.hub,
      },
    },
    {
      path: '/hub/:instanceId',
      component: HubSettings,
      name: 'multi_hub',
      meta: {
        tab: Tabs.hub,
      },
    },
    {
      path: '/videos/:videoId',
      component: VideoComponent,
      name: 'video-component',
      meta: {
        tab: Tabs.videos,
      },
      redirect: '/',
      children: [
        {
          path: '/',
          component: ShowVideoComponent,
          name: 'show_video',
          meta: {
            tab: Tabs.videos,
            hideHeader: true,
          },
        },
        {
          path: 'analytics',
          component: VideoAnalytics,
          name: 'video_analytics',
          meta: {
            tab: Tabs.videos,
            fullpage: true,
          },
        },
      ],
    },
    {
      path: '/articles/:articleId/edit',
      component: ArticleEditComponent,
      name: 'article_edit_component',
      meta: {
        tab: Tabs.articles,
      },
    },
    {
      path: '/articles/:articleId',
      component: ViewArticleComponent,
      name: 'view_article_component',
      meta: {
        tab: Tabs.articles,
      },
    },
    {
      path: '/insta/videos/:videoId',
      redirect: (to) => {
        return to.fullPath.replace('/insta/', '');
      },
    },
    {
      path: '/insta/videos/:videoId/edit',
      redirect: (to) => {
        return to.fullPath.replace('/insta/', '');
      },
    },
    {
      path: '/videos/:videoId/embed',
      component: EmbedVideoComponent,
      name: 'embed_video',
    },
    {
      path: '/collections/:collectionId',
      component: Collection,
      name: 'collection',
      meta: {
        tab: Tabs.collections,
      },
      redirect: '/',
      children: [
        {
          path: '/',
          component: ShowCollectionComponent,
          name: 'collection_summary',
          meta: {
            tab: Tabs.collections,
          },
        },
        {
          path: 'analytics',
          component: CollectionAnalytics,
          name: 'collection_analytics',
          meta: {
            tab: Tabs.collections,
            fullpage: true,
          },
        },
      ],
    },
    {
      path: '/learners',
      component: Learners,
      name: 'learners',
      meta: {
        tab: Tabs.learners,
      },
    },
    {
      path: '/learner_groups',
      component: LearnerGroup,
      name: 'learner-group',
      meta: {
        tab: Tabs.learnerGroup,
      },
    },
    {
      path: '/learners/:id',
      component: LearnerDetails,
      name: 'learner_details',
      meta: {
        tab: Tabs.learners,
      },
    },
    {
      path: '/learner_groups/:id',
      component: LearnerGroupDetails,
      name: 'learner_group_details',
      meta: {
        tab: Tabs.learnerGroup,
      },
    },
    {
      path: '/learner_groups/:id/members',
      component: LearnerGroupMembers,
      name: 'learner_group_members',
      meta: {
        tab: Tabs.learnerGroup,
      },
    },
    {
      path: '/settings',
      component: WorkspaceSettings,
      name: 'workspace-settings',
      meta: {
        tab: Tabs.settings,
      },
      redirect: '/',
      children: [
        {
          path: '/',
          component: WorkspaceGeneralSettings,
          name: 'general_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'account',
          component: AccountSettings,
          name: 'account_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'members',
          component: MemberSettings,
          name: 'members_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'theme',
          component: ThemeSettings,
          name: 'theme_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'guideTheme',
          component: GuideThemeSettings,
          name: 'guide_theme_settings',
          meta: {
            tab: Tabs.settings,
          }
        },
        {
          path: 'pricing',
          component: PricingPage,
          name: 'pricing_page',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'billing',
          component: BillingPage,
          name: 'billing_page',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'notifications',
          component: NotificationSettings,
          name: 'notifications_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
        {
          path: 'security',
          component: SecuritySettings,
          name: 'security_settings',
          meta: {
            tab: Tabs.settings,
          },
        },
      ],
    },
    {
      path: '/integrations',
      component: Integrations,
      name: 'integrations',
      meta: {
        tab: Tabs.integrations,
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'home' });
        } else {
          next();
        }
      },
    },
    {
      path: '/integrations/youtube',
      component: YoutubeIntegration,
      name: 'youtube',
      meta: {
        tab: Tabs.integrations,
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'videos_home' });
        } else {
          next();
        }
      },
    },
    {
      path: '/integrations/intercom',
      component: IntercomIntegration,
      name: 'intercom',
      meta: {
        tab: Tabs.integrations,
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'videos_home' });
        } else {
          next();
        }
      },
    },
    {
      path: '/integrations/salesforce',
      component: SalesforceIntegration,
      name: 'salesforce',
      meta: {
        tab: Tabs.integrations
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'videos_home' });
        } else {
          next();
        }
      }
    },
    {
      path: '/integrations/stripe',
      component: StripeConnect,
      name: 'stripe',
      meta: {
        tab: Tabs.integrations
      },
      beforeEnter: (to, from, next) => {
        if (PARTNER_MODE) {
          next({ name: 'videos_home' });
        } else {
          next();
        }
      },
    },
    {
      path: '/:workspace/join',
      component: JoinWorkspaceComponent,
      name: 'join_workspace',
      meta: {
        non_logged_in: 'optional',
      },
    },
    {
      path: '/logout',
      component: LogoutComponent,
      name: 'logout',
      meta: {
        non_logged_in: 'optional',
      },
    },
    {
      path: '/common_loader',
      component: CommonLoaderComponent,
      name: 'common_loader',
      meta: {
        non_logged_in: 'optional',
      },
    },
    {
      path: '/integrations/youtube_callback',
      component: YoutubeCallbackComponent,
      name: 'youtube_callback_handler',
      meta: {
        non_logged_in: 'optional',
      },
    },
    {
      path: '/integrations/intercom_connect',
      component: IntercomConnectComponent,
      name: 'intercom_connector',
      meta: {
        non_logged_in: 'optional',
        headless: true,
      },
    },
    {
      path: '/integrations/intercom_callback',
      component: IntercomCallbackComponent,
      name: 'intercom_callback',
      meta: {
        non_logged_in: 'optional',
      },
    },
    {
      path: '/integrations/intercom_callback_via_app',
      component: IntercomCallbackDirectComponent,
      name: 'intercom_callback_via_app',
      meta: {
        non_logged_in: 'optional',
        headless: true,
      },
    },
    {
      path: '/integrations/salesforce_callback',
      component: SalesforceCallbackComponent,
      name: 'salesforce_callback',
      meta: {
        non_logged_in: 'optional',
      }
    },
    {
      path: '/integrations/stripe_callback',
      component: StripeCallback,
      name: 'stripe_callback',
      meta: {
        non_logged_in: 'optional',
      }
    },
    {
      path: '/record',
      component: RecordPageComponent,
      name: 'record',
    },
    {
      path: '/record_guide',
      component: GuideRecordPage,
      name: 'guide_record'
    },
    {
      path: '/sso_login',
      component: SsoLogin,
      name: 'sso_login',
      meta: {
        non_logged_in: 'strict',
      },
    },
    {
      path: '/academy/training_events/:eventId',
      component: TrainingEvent,
      name: 'training_event',
      meta: {
        tab: Tabs.academy,
      },
      redirect: '/academy/training_events/:eventId/overview',
      children: [
        {
          path: 'overview',
          component: TrainingEventOverview,
          name: 'training_event_overview',
        },
        {
          path: 'content',
          component: TrainingEventContent,
          name: 'training_event_content',
        },
        {
          path: 'sessions/:sessionId',
          component: TrainingEventSession,
          name: 'training_event_session',
        }
      ],
    },
    { path: '*', redirect: '/' },
  ],
});

router.beforeEach((to, from, next) => {
  if (AuthService.getInstance().trialEnded && (to.name != 'pricing_page' && to.name != 'members_settings')) {
    next({
      name: 'pricing_page',
    });
  } else if (!to.meta.non_logged_in && !AuthService.getInstance().isLoggedIn) {
    AuthService.getInstance().redirectToLogin();
  } else if (
    to.meta.non_logged_in == 'strict' &&
    AuthService.getInstance().isLoggedIn
  ) {
    next({ name: 'videos_home' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const message = to.query.d;
  if (message) {
    const data = JSON.parse(atob(message as string));
    if (data.flash) {
      router.app.$show({
        id: 'join-message',
        message: data.flash,
        manual: true,
        dismissible: true,
      });
    }
  }
});

export default router;
