export default {
  title: 'Learners',
  desc: 'Add, and manage learners.',
  empty: 'No learners found!',
  empty_cta: 'Create',
  empty_cta_import: 'Import Learner!',
  deactivate: 'Deactivate account',
  activate: 'Activate account',
  unlock: 'Unlock account',
  filters: {
    add: 'Add Filter',
    conditions: {
      title: 'Add Filters',
      operand: 'Operand',
      operator: 'Operator',
      value: 'Value',
      apply: 'Apply & Search',
      error: 'One or many fields are empty'
    }
  },
  table: {
    learner_details: 'Learner Details',
    company: 'Company',
    courses_enrolled: 'Courses Overview',
    courses_completed: 'Courses Completed',
    courses_certified: 'Courses Certified',
    total_engagement_time: 'Total Engagement Time',
    created_at: 'Created',
    joined_at: 'Joined',
    last_accessed: 'Last Accessed',
    actions: 'Actions',
    footer_display: `Showing %{min_count} - %{max_count} of %{count}`
  },
  sort_by: {
    created_at: 'Created At',
    last_accessed_at: 'Last Accessed At'
  },
  button: {
    create: 'Create Learner',
    add: 'Add Learner',
    add_or_import: 'Add / Import',
    import: 'Import',
    export: 'Export'
  },
  create: {
    title: 'Create learner',
    email: 'Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    company: 'Company',
    invite_to_academy: 'Send an invite to access academy',
    invite_to_video_hub: 'Send an invite to access knowledge hub'
  },
  import: {
    title: 'Bulk import learners',
    desc: 'Bulk import learners',
    file_upload_label: "Upload learner's csv",
    download_sample: 'Download sample csv',
    detailed_log: 'for detailed error log',
    click: 'Click here',
    loading:
      'Your data is being imported. This might take few seconds. Please wait...',
    success: `%{count} record(s) have been inserted successfully`,
    error: `Error %{message} in %{count} record(s)`,
    note: `Note: Your CSV data file should contain all the columns specific to the operation you are performing. The sample CSV file has most of the columns required for the operation.`
  },
  export: {
    title: 'Export learners',
    desc: `A CSV file containing the learners' information will be sent to your email.`
  },
  detail: {
    title: 'Learner Details',
    name: 'Name',
    email: 'Email',
    company: 'Company Name',
    created_at: 'Created At',
    joined_at: 'Joined At',
    last_accessed: 'Last Accessed At',
    nav: `(%{min_count} / %{max_count})`,
    user_properties: {
      title: 'User Engagements',
      engagement_time: 'Total engagement time',
      engagement_videos: 'Total video views'
    },
    activity: {
      title: 'Activity Log',
      created_at: 'Created at %{date}',
      joined_at: 'Joined on %{date}',
      last_accessed: 'Last accessed at %{date}'
    },
    email_log: {
      title: 'Email Log',
      sent_on: 'Sent On',
      subject: 'Subject',
      notification_name: 'Notification'
    },
    task:{
      pending_tasks:'Pending tasks',
      course_title:'Course',
      task_title:'Task',
      action:'Action',
      task_type : 'Task type',
      created_on:'Created on',
      evaluate_task:'Evaluate task',
      no_pending_tasks:'No pending tasks yet',
      approve:'Approve Task',
      redo:'Redo Task'
    },
    analytics: {
      engaged_videos: {
        title: 'Engaged Videos',
        no_of_visits: 'Number of views',
        duration_spent: 'Engagement Time',
        empty: 'No video visits'
      },
      engaged_flows: {
        title: 'Engaged Collections',
        no_of_visits: 'Number of Visits',
        duration_spent: 'Engagement Time',
        empty: 'No collections watched yet',
        contains: 'Contains'
      },
      course_enrollments: {
        title: 'Course Enrollments',
        empty: 'No Enrollments yet',
        enrolled: 'Enrolled on',
        completed: 'Completed on',
        lessons_completed: 'Lessons completed',
        certification_completed_on: 'Certification completed on',
        view_assessment_attempts: 'View Assessment attempts',
        view_quiz_attempts: 'View Quiz attempts',
        quiz_summary: 'Quiz Summary',
        exam_summary: 'Exam Summary',
        certified: 'Certified',
        last_assessment_attempts: 'Last %{count} attempts',
        last_assessment_attempt: 'Last %{count} attempt',
        attempt: 'Attempt',
        created_at: 'Created at',
        score: 'Score',
        state: 'State',
        passed: 'Passed',
        failed: 'Failed',
        timed_out: 'Timed out',
        no_attempts: 'No attempts found.',
        responses: 'Responses',
        download: 'Download',
        fee: 'Fee paid'
      }
    },
    course_assignments: {
      title: 'Course Assignments',
      empty: 'No Courses has been assigned directly to this learner.',
      add: 'Add'
    },
    back: 'Back to Learners'
  },
  actions: {
    invite_user: {
      title: 'Invite Learner to Knowledge Hub',
      desc:
        'A link will be sent via email, through which the learner can login to your knowledge hub'
    },
    academy_invite_user: {
      title: 'Invite Learner to Academy',
      desc:
        'A link will be sent via email, through which the learner can login to your Academy',
      already_invited: 'Learner has already joined the academy. '
    },
    share_hub_url: {
      title: 'Knowledge Hub',
      desc:
        'Unique knowledge hub url that will associate engagements with this learner',
      copy: 'Copy Shareable Link'
    },
    share_academy_url: {
      title: 'Academy',
    },
    share_video: {
      title: 'Video',
      desc:
        'Share a video using a unique URL and track engagements specific to this learner',
      desc_long:
        'Share a video with this learner using a unique URL. This helps you to track engagements specific to this learner.',
      note:
        'This unique url only helps us to track engagements and does not authenticate the learner.',
      select: 'Select Video',
      copy: 'Copy Shareable Link'
    },
    share_collection: {
      title: 'Collection',
      desc:
        'Share a collection using a unique URL and track engagements specific to this learner',
      desc_long:
        'Share a collection with this learner using a unique URL. This helps you to track engagements specific to this learner.',
      note:
        'This unique url is only for tracking engagements and does not authenticate the learner.',
      select: 'Select collection',
      copy: 'Copy Shareable Link'
    },
    create: 'Create New Learner',
    edit: 'Edit Learner',
    delete: {
      title: 'Delete Learner',
      desc:
        'The learner will be deleted permanantly. Any links or reference to this learner will also be deleted.',
      not_allowed:
        'The learner cannot be deleted as they have been issued certificates.'
    },
    deactivate: {
      title: 'Deactivate Learner',
      desc:
        'The learner will be deactivated. They will not be able to login to your knowledge Hub or Academy.',
    },
    activate: {
      title: 'Activate Learner',
      desc:
        'The learner will be activated.',
    },
    reset_attempts: {
      unlock: 'Allow Access',
      title: 'Access Restricted',
      desc: `
      Learner has exceeded the attempt limit for a quiz or assessment.
      `,
      footer_desc: "By Allowing access, you will reset the attempt limit based on the course settings for this learner."
    },
    pending_tasks: {
      taks: 'Pending tasks',
      untitled_task: 'Untitled task',
    }
  },
  banner: {
    approval: 'Awaiting learner approval',
    reject: 'Reject',
    approve: 'Approve',
    rejected: 'Learner rejected',
    approved: 'Learner approved',
    unlocked: 'Learner unlocked successfully'
  },
  email_verification: {
    title: 'Are you sure to mark this email as valid?',
    cancel: 'Cancel',
    confirm: 'Mark as valid'
  },
  re_enroll_course: {
    re_enroll_button: 'Re-enroll',
    title: 'Are you sure you want to re-enroll this learner?',
    description: "The learner's course progress will be reset upon re-enrollment and the history will not be retained.",
    success: 'Course Re-enrolled Successfully',
    save_button: 'Yes, Re-enroll',
    cancel_button: 'Cancel',
    send_email: 'Send a email notification to the learner'
  }
};
